@tailwind base;

@layer base {
  .text-white {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: white;
  }
  .bg-opaque {
    background-color: rgba(26, 32, 44, 0.7);
  }
  .bg-white {
    background-color: white;
  }
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
